
import { Options, mixins } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { ZohoInvoiceProject, ZohoInvoiceShort, ZohoInvoice } from '@/store/zoho-invoice'
import TerminalMixin from '@/mixins/Terminal'

import { getDateString } from '@/helpers/datetime'
import parseCurrency from '@/helpers/currency'

import ZohoInvoiceSelect from '@/components/ZohoInvoice/InvoiceSelect.vue'
import LoadingComponent from '@/components/Loading.vue'
import ButtonComponent from '@/components/Form/Button.vue'
import Terminal from '@/components/Terminal.vue'

@Options({
  components: {
    LoadingComponent,
    ZohoInvoiceSelect,
    Terminal,
    ButtonComponent
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      timeEntries: 'toggl/getTimeEntries',
      zohoInvoiceProjects: 'zohoInvoice/getProjects',
      zohoInvoices: 'zohoInvoice/getInvoiceList',
      zohoInvoice: 'zohoInvoice/getInvoice'
    })
  }
})
export default class Invoice extends mixins(TerminalMixin) {
  isLoggedIn!: boolean
  zohoInvoiceProjects!: ZohoInvoiceProject[]
  zohoInvoices!: ZohoInvoiceShort[]
  zohoInvoice!: ZohoInvoice

  loading = false
  zohoInvoiceId: number | null = null
  invoiceWebViewLink: string | null = null

  get invoicesLoaded (): boolean {
    return this.zohoInvoices.length > 0
  }

  async createInvoice (): Promise<void> {
    this.loading = true

    const invoiceShort = this.zohoInvoices.find(i => i.invoice_id === this.zohoInvoiceId) as ZohoInvoiceShort
    const reset = this.zohoInvoice && this.zohoInvoice.invoice_id !== this.zohoInvoiceId

    this.addMessage(`Get invoice #${invoiceShort.invoice_number} from ZohoInvoice`)
    await this.$store.dispatch('zohoInvoice/getInvoice', { invoiceId: this.zohoInvoiceId, reset })
      .then((response) => {
        this.addMessage('Done', {
          zohoInvoiceId: this.zohoInvoiceId,
          response
        })

        this.addMessage('Duplicate invoice template in GoogleDrive')
        return this.$store.dispatch('gapi/duplicateInvoiceTemplate', invoiceShort.invoice_number)
      }).then((response: any) => {
        this.addMessage('Done', response)

        const invoice = this.zohoInvoice
        const { billing_address: billingAddress } = invoice

        const replaces = [
          [ '{{INVOICE_NUMBER}}', invoice.invoice_number ],
          [ '{{INVOICE_DATE}}', getDateString(invoice.date, 'DD.MM.YYYY') ],
          [ '{{INVOICE_DUE_DATE}}', getDateString(invoice.due_date, 'DD.MM.YYYY') ],
          [ '{{CUSTOMER_NAME}}', invoice.customer_name ],
          [ '{{BILLING_ADDRESS}}', billingAddress.address ],
          [ '{{BILLING_ZIP}}', billingAddress.zip ],
          [ '{{BILLING_CITY}}', billingAddress.city ],
          [ '{{SUBTOTAL}}', parseCurrency(invoice.sub_total) ],
          [ '{{SUBTOTAL_TAX}}', parseCurrency(invoice.tax_total) ],
          [ '{{GRANDTOTAL}}', parseCurrency(invoice.total) ]
        ]

        this.addMessage('Update invoice placeholders in GoogleDocs', { replaces })
        return this.$store.dispatch('gapi/updateInvoice', { replaces })
      })
      .then((response: any) => {
        this.addMessage('Done', response)

        const { line_items: lineItems } = this.zohoInvoice

        this.addMessage('Update table-items for invoice in GoogleDocs', { lineItems })
        return this.$store.dispatch('gapi/updateInvoiceTable', { lineItems })
      })
      .then((response: any) => {
        this.addMessage('Done', response)

        this.addMessage('Get new invoice in GoogleDocs')
        return this.$store.dispatch('gapi/getInvoice')
      })
      .then((response: any) => {
        this.addMessage('Done', response)
        this.invoiceWebViewLink = response.result.webViewLink
      })
      .catch((error: any) => {
        this.addMessage('Error: ', error.message, 'error')
      })

    this.loading = false
  }
}
