<template>
  <div class="flex flex-wrap">
    <label-component for-name="zohoInvoices">
      Choose source ZohoInvoice:
    </label-component>
    <select-component
      id="zohoInvoices"
      v-model="value"
    >
      <option :value="null">
        Select invoice
      </option>
      <option
        v-for="option in invoiceOptions"
        :key="option.value"
        :value="option.value"
        :text="option.label"
        :selected="!!modelValue && modelValue.includes(option.value)"
      />
    </select-component>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { ZohoInvoiceShort } from '@/store/zoho-invoice'

import LabelComponent from '@/components/Form/Label.vue'
import SelectComponent from '@/components/Form/Select.vue'

@Options({
  components: {
    LabelComponent,
    SelectComponent
  },
  computed: {
    ...mapGetters({
      userLoaded: 'user/loaded',
      invoices: 'zohoInvoice/getInvoiceList'
    })
  }
})
export default class ZohoInvoiceSelect extends Vue {
  userLoaded!: boolean
  invoices!: ZohoInvoiceShort[]

  @Prop() modelValue!: number[]

  get value (): number[] {
    return this.modelValue
  }

  set value (value: number[]) {
    this.$emit('update:modelValue', value)
  }

  get invoiceOptions (): { label: string, value: number }[] {
    if (!this.invoices) return []
    return this.invoices.map(i => {
      return {
        label: i.invoice_number,
        value: i.invoice_id
      }
    })
  }

  mounted (): void {
    this.loadData()
    this.$emitter.on('onAuthStateApproved', this.loadData)
  }

  destroyed (): void {
    this.$emitter.off('onAuthStateApproved', this.loadData)
  }

  async loadData (): Promise<any> {
    if (!this.userLoaded) return
    await this.$store.dispatch('zohoInvoice/getInvoiceList')
  }
}
</script>
